@import "../../../assets/scss/variables.scss";

.home-header-wrapper {
    position: fixed;
    z-index: 4;
    width: 100%;
    background: rgba($white, 0.05);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4px);
    top: 0;

    .home-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 25px;
        max-width: 1300px;
        margin: auto;

        @media screen and (max-width:1199px) {
            padding: 14px 16px;
            justify-content: space-between;
        }

        @media screen and (max-width:640px) {
            padding: 14px 8px;
        }

        & .logo-wrapper {
            display: inline-flex;
            align-items: flex-start;
            justify-content: flex-start;

            & .logo {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;

                a {
                    display: flex;
                    align-items: center;
                    color: $white;
                    font-weight: 600;
                    font-size: 1rem;
                    img{
                    width: 105px;
                    height: auto;
                    margin-right: 10px;
                    }
                }

                & svg {
                    fill: $white;
                    width: 100px;
                    height: auto;
                    margin-right: 10px;
                    fill: linear-gradient(180deg, #E2F7E5 0%, #52B788 100%);
                }
            }
        }

        & .header-right {
            display: flex;
            width: 100%;
            justify-content: flex-end;

            .header-menus {
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-right: 2rem;
                width: 100%;

                .ant-menu {
                    background: none;
                    width: 100%;
                    margin: auto;
                    display: flex;
                    justify-content: center;

                    .ant-menu-item {
                        color: rgba($white, 0.5);
                        letter-spacing: 0;
                        font-weight: 600;
                        font-size: 1rem;
                        background: none !important;

                        a {
                            color: rgba($white, 0.5);
                        }

                        &:hover {
                            color: $white;
                        }

                        &::after {
                            display: none;
                        }

                        &.ant-menu-item-selected {
                            color: $white;

                            a {
                                color: $white;
                            }
                        }
                    }

                    .ant-menu-submenu {
                        color: rgba($white, 0.5);
                        letter-spacing: 0;
                        font-weight: 600;
                        font-size: 1rem;

                        .ant-menu-submenu-title {
                            display: flex;
                            flex-direction: row-reverse;

                            .ant-menu-item-icon {
                                margin-left: 10px;
                                fill: rgba($white, 0.5);
                                width: 14px;
                            }
                        }

                        &:hover, &.ant-menu-submenu-selected{
                            .ant-menu-submenu-title {
                                color: $white;
                            }
                        }

                        .ant-menu-item {
                            &:hover{
                                color: $white;
                            }
                        }

                        &::after {
                            display: none;
                        }
                    }
                }
            }
        }

        .siderbar-toggle {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            position: absolute;
            right: 0;
            top: 19px;
            z-index: 2;

            .icon {
                fill: $white;
                width: 50px !important;
            }
        }
    }

    .menu-icon {
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-left: 15px;
        .icon {
            fill: $white;
            width: 30px;
            height: 30px;
            cursor: pointer;
        }
    }
}

.ant-menu-sub {
    background-color: #2B1C38 !important;

    .ant-menu-item {
        color: $white;
    }
}

.ant-menu-submenu{
    .ant-menu-item {
        color: rgba($white, 0.5);
        background: none !important;
        &.ant-menu-item-selected{
            color: $primary-color;
            font-weight: 500;
        }
        &:hover{
            color: $white !important;
        }
    }
}

.ant-drawer {
    .ant-menu {
        border: 0 !important;
        .ant-menu-item {
            background-color: none;
        }
        .ant-menu-submenu-title{
            padding-left: 5px;
            background-color: none;
        }
        .icon {
            display: none;
        }
    }
}