@import "../../assets/scss/variables.scss";

.community-wrapper{
    .community-banner{
        position: relative;
        display: flex;
        width: 100%;
        min-height: calc(100vh - 70px);
        align-content: center;
        justify-content: center;
        flex-wrap: wrap;
        padding-top: 70px;
        overflow: hidden;
    }
    .community-row{
        position: relative;
        z-index: 3;
        h1{
            text-align: center;
            margin-bottom: 3.5rem;
        }
        ul{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            list-style-type: none;
            padding-left: 0;
            grid-gap: 2rem 1rem;
            margin-bottom: 0;
            margin-top: 3rem;
        }
    }
}

.social-link{
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    .left-icon{
        background: rgba(255, 255, 255, 0.02);
        box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(10px);
        width: 107px;
        height: 107px;
        min-width: 107px;
        border-radius: 107px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid rgba($white, 0.1);
        text-align: center;
        margin-right: 15px;
        .icon{
            fill: $white;
            width: 50%;
        }
    }
    .right-details{
        a{
            display: flex;
            align-items: center;
            &:hover{
                opacity: 0.8;
            }
            h4{
                margin-bottom: 0;
                color: $white;
                font-size: 1.8rem;
                font-weight: 700;
            }
            .icon{
                fill: $white;
                margin-left: 10px;
            }
        }
        p{
            font-weight: 300;
            font-size: 1.2rem;
        }
    }
}