@import '../../../assets/scss/variables.scss';

.home-footer{
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4px);
    bottom: 0;
    z-index: 2;
    width: 100%;
    padding: 18px 20px;
    .footer-inner{
        display: flex;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 680px) {
            display: block;
            text-align: center;
            padding: 25px 20px;
        }
        .logo{
            display: flex;
            align-items: center;
            @media screen and (max-width: 680px) {
                justify-content: center;
            }
            .icon{
                fill: $white;
                width: 80px;
            }
        }
        .terms-link{
            font-size: $font-size-base;
        }
        .build-with{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: $font-size-lg;
            font-weight: 300;
            color: $white;
            @media screen and (max-width: 680px) {
                margin: 25px 0;
            }
            .icon {
                max-width: 80px;
                width: 100%;
                margin-left: 8px;
            }
        }
        .social-links{
            display: flex;
            align-items: center;
            @media screen and (max-width: 680px) {
                justify-content: center;
            }
            a{
              margin-left: 10px;
              display: flex;
              align-items: center;
              .icon{
                fill: $white;
                width: 18px;
              }
            }
        }
    }
}