/*
*
COMDEX
*                                                           
*/ 

// -------- Colors -----------
// @primary-color: @blue-6;
// @info-color: @blue-6;
// @success-color: @green-6;
// @processing-color: @blue-6;
// @error-color: @red-6;
// @highlight-color: @red-6;
// @warning-color: @gold-6;
// @normal-color: #d9d9d9;
$white: #fff;
$black: #000;
$red: #CA2D2D;

$dark-color: #020F0A;
$primary-color: #59509C; // primary color for all components
$secondary-color: #170A1B;
$success-color: #52B788; // success state color
$warning-color: #faad14; // warning state color
$error-color: #f5222d; // error state color

$text-red: #d90a00; // Red text color
$text-white: $white; // Red text color
$link-color: $primary-color; // link color
$text-light: #727272;


$font-family: 'Montserrat', sans-serif;

$font-size-base: 14px;
$font-size-xlg: $font-size-base + 4px; //18px
$font-size-lg: $font-size-base + 2px; //16px
$font-size-sm: $font-size-base - 2px; //12px

$text-color-primary: #ffffff; // major text color
$text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
$disabled-color: rgba(0, 0, 0, 0.25); // disable state color
$border-radius-base: 6px; // major border radius
$border-color-base: #d9d9d9; // major border color